// @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
// font-family: 'Heebo', sans-serif;
@import url("../../fonts/fonts.css");
@import "colors.scss";
// font-family: 'Euclid Circular A';
html {
    height: 100%;
}
body {
    height: 100%;
}
body #root {
    height: 100%;
}
a {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
body {
    .ant-layout {
        font-family: "Euclid Circular A";
        background: none;
        height: 100%;
        position: relative;
        z-index: 9;
        &.front {
            height: auto;
            .ant-layout-header {
                .hlogo {
                    min-width: inherit;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: none;
                    min-height: 90px;
                }
                
            }
           
        }
    }
    .ant-layout-content {
        min-height: 100%;
    }
    .ant-layout-footer {
        background: $darkblue;
        padding: 50px 0px;
        color: $white;
        display: none;
        .logo {
            margin-bottom: 30px;
            display: inline-block;
        }
        .socialicons {
            li {
                display: inline-block;
                margin-right: 10px;
            }
            a {
                font-size: 32px;
                color: $darkblue;
                &:hover {
                    color: $white;
                }
            }
        }
        h3 {
            color: $white;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.01em;
            position: relative;
            padding-bottom: 10px;
            margin-bottom: 16px;
            &::before {
                content: "";
                width: 100%;
                max-width: 130px;
                height: 1px;
                border-top: 1px solid $white;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
        p {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 27px;
            letter-spacing: 0.01em;
            margin: 0;
        }
        .link {
            color: $darkblue;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 27px;
            letter-spacing: 0.01em;
        }
        ul.footerlinks {
            margin: 0;
            padding: 0;
            li {
                width: 50%;
                float: left;
                position: relative;
                padding-left: 16px;
                margin-bottom: 5px;
                a {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 33px;
                    letter-spacing: 0.01em;
                    color: $white;
                    &:hover {
                        color: $darkblue;
                    }
                }
                &::before {
                    content: "";
                    background: url(../../images/bullet-1.png) no-repeat center;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: 0;
                    top: 10px;
                }
            }
        }
    }
    .ant-dropdown-menu {
        border-radius: 5px;
        padding: 16px;
        margin-top: 10px;
        position: relative;
        &::before {
            position: absolute;
            right: 10px;
            top: -7px;
            display: inline-block;
            width: 0;
            height: 0;
            vertical-align: middle;
            border-bottom: 10px solid $white;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            content: "";
        }
        .ant-dropdown-menu-item {
            border-bottom: 1px solid #f1f1f1;
            a {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 30px;
                color: $darkgray;
                display: flex;
                align-items: center;
                .menu-icon {
                    font-size: 18px;
                    margin-right: 7px;
                    width: 20px;
                    color: $darkgray;
                }
            }
            &:last-child {
                border: 0;
            }
        }
    }
    .ant-layout-header {
        background: $white;
        border-image: none;
        height: 90px;
        padding:  0px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 200;
        display: flex;
        align-items: center;
        .container-fluid{
            padding-left: 0;
          
        }
        .hlogo {
            min-width: 230px;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 230px;
            min-height: 90px;
        }
        .rightside {
            display: inline-flex;
            align-items: center;
        }
        .headerbtn {
            height: 44px;
            background: $white;
            width: 44px;
            overflow: hidden;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $darkgray;
            font-size: 20px;
            min-height: inherit;
        }
        .profilebtn {
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;
            .imgd {
                border-radius: 50%;
                overflow: hidden;
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                img {
                    width: 100%;
                }
            }

            .imgd.ant-dropdown-trigger.profile-placeholder {
                background: #fff;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .imgd.ant-dropdown-trigger.profile-placeholder img {
                width: 45px !important;
                border-radius: 50%;
            }
        }
        .topmenu {
            display: none;
            a {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $white;
                text-transform: uppercase;
                padding: 10px 16px;
                border-bottom: 2px solid $darkblue;
                margin: 0px 5px;
                &.active,
                &:hover {
                    color: $black;
                    border-bottom: 2px solid $black;
                }
            }
        }

        .ant-btn-primary {
            background: $white;
            color: $black;
            min-height: 44px;
            border-radius: 0;
            line-height: normal;
            margin: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            .anticon {
                font-size: 22px;
                display: inline-flex;
            }
        }

        .searchhwrap {
            min-height: 44px;
            max-height: 44px;
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            .ant-input-affix-wrapper {
                border: 0;
                min-height: 44px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.01em;
                box-shadow: none;
                min-width: 320px;
                vertical-align: top;
                border-radius: 5px;
                .ant-input-suffix {
                    font-size: 20px;
                }
            }
        }
    }
   
    .ant-layout-sider {
        background: $darkpanel;
        z-index: 99;
        height:100vh;
        // -webkit-transition: none;
        // transition: none;
        min-width: 250px;
        width: 250px;
        .logo {
            min-width: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            min-height: 90px;
        }
        .ant-layout-sider-children {
            overflow: auto;
            &::-webkit-scrollbar {
                width: 1px;
                height: 1px;
            }
            &::-webkit-scrollbar-thumb {
                background: hsla(0, 0%, 100%, 0.2);
                border-radius: 3px;
                -webkit-box-shadow: inset 0 0 5px hsla(0, 0%, 100%, 0.05);
            }
            &::-webkit-scrollbar-track {
                background: hsla(0, 0%, 100%, 0.15);
                border-radius: 3px;
                -webkit-box-shadow: inset 0 0 5px rgba(37, 37, 37, 0.05);
            }
        }
        .ant-layout-sider-zero-width-trigger {
            background: $darkpanel;
            top: 145px;
        }
        .ant-layout-sider-zero-width-trigger:hover::after {
            background: none;
        }
        .site-layout-background{
            position: absolute;
            top: 10px;
            right: -36px;
            z-index: 1;
            width: 36px;
            height: 42px;
            color: #fff;
            font-size: 18px;
            line-height: 42px;
            text-align: center;
            background: #001529;
            border-radius: 0 2px 2px 0;
            cursor: pointer;
            -webkit-transition: background 0.3s ease;
            transition: background 0.3s ease;
        }
        &.ant-layout-sider-zero-width {
            .ant-layout-sider-children {
                padding: 0;
                overflow: hidden;
            }
        }
        .sidebar_menu {
            padding: 10px;
            .ant-menu{
                &.ant-menu-inline {
                    border: 0;
                    background: none;
                    li.ant-menu-submenu{
                        margin: 0;
                        padding: 0 !important;
                        background-color: transparent;
                        background: none;
                        height: auto;
                        width: 100%;
                        border-bottom: 1px solid rgba($white, 0.1);
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 16px;
                        color: rgba($white, 0.5);
                        .ant-menu-sub{
                            padding-left: 33px;
                            li.ant-menu-item {
                                &:last-child{
                                    border: 0;
                                }
                                a{
                                    font-size: 14px;
                                    color: rgba($white, 0.5);
                                    &:hover {
                                        color: $white;
                                    }
                                }
                            }
                        }
                        &.ant-menu-submenu-open{
                            .ant-menu-submenu-title{
                                color: $white;
                            }
                        }
                        .ant-menu-submenu-title {
                            padding: 0px 16px 0px 0px !important;
                            margin: 0;
                            width: 100%;
                            display: flex;
                            min-height: 56px;
                            align-items: center;
                            &:hover {
                                color: $white;
                            }
                            .ant-menu-submenu-arrow{
                                right: 0;
                                color: #fff;
                            }
                            .anticon {
                                margin-right: 5px;
                                min-width: 26px;
                                height: auto;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 20px;
                                color: rgba($white, 0.5);
                            }
                        }
                    }
                    li.ant-menu-item {
                        display: flex;
                        margin: 0;
                        padding: 0 !important;
                        background-color: transparent;
                        background: none;
                        height: auto;
                        width: 100%;
                        border-bottom: 1px solid rgba($white, 0.1);
                      
                        &.ant-menu-item-selected{
                            &::after{
                                display: none;
                            }
                            a{
                                color: rgba($white, 1);
                            }
                            .anticon {
                                color: rgba($white, 1);
                            } 
                        }
                        &.active a {
                            color: $white;
                        }
                        a {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 16px;
                            color: rgba($white, 0.5);
                            padding: 20px 0px;
                            display: flex;
                            letter-spacing: 0.5px;
                            cursor: pointer;
                            align-items: center;
                            
                            
                            &:hover {
                                color: $white;
                            }
                        }
                        &:hover{
                            .anticon {
                                color: rgba($white, 1);
                            } 
                        }
                        .anticon {
                            margin-right: 5px;
                            min-width: 26px;
                            height: 56px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                            color: rgba($white, 0.5);
                        }
                    }
                   
                }
            }
           
        }
        .profilebar {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            color: $white;
            padding: 16px;
            display: flex;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.1);
            .imgd {
                border-radius: 50%;
                overflow: hidden;
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                margin-right: 10px;
                img {
                    width: 100%;
                }
            }
        }

      
    }
}

.hide {
    display: none !important;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}
.mobilemenu {
    display: none;
    a {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #8e8e8e;
        text-transform: uppercase;
        padding: 10px 0;
        border-bottom: 1px solid #f1f1f1;
        text-align: left;
        &.active,
        &:hover {
            color: $black;
            border-bottom: 1px solid #f1f1f1;
        }
    }
    .ant-drawer-body {
        padding: 16px;
    }
}
.dtldrawer{
    
    font-weight: 500;
    .ant-drawer-content-wrapper {
        max-width: 800px;
        width: calc(100% - 16px)!important;
    }
    .ant-table table{
        min-width: 550px;
    }
    .ant-form-item{
        font-size: 16px;  
        margin-bottom: 16px;  
    }
    label{
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        opacity: 0.5;
        display: block;
        line-height: 16px;
    }
    .ant-table-tbody > tr > td {
        white-space: normal;
        word-break: break-all;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #343534;
        a{
            color: #7b8ab3;
        }
    }
    .ant-drawer-title {
        font-size: 20px;
        color: #fff;
        
    }
    .ant-drawer-close{
        color: #fff;
    }
    .ant-drawer-header{
        background:#4F5565;
        color: #fff;
    }
    .empldtlhead{
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid #f1f1f1;
        
        label{
            display: block;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 5px;
        }
    }
   .ant-table-thead > tr > th {
        background: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #8E8E8E;
        text-transform: capitalize;
    }
}
.record-menu-link {
    cursor: pointer;
}
.record-menu-drawer {
    .ant-drawer-title {
        font-size: 20px;
        color: #fff;
        
    }
    .ant-drawer-content{
        background:#fff;
    }
    .ant-drawer-header{
        border: 0;
        background-color: #4F5565;
        color: #fff;
    }
    .ant-drawer-close{
        color: #fff;
    }
    .ant-drawer-body{
        padding: 0;
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            font-size: 16px;
            color: rgba(52, 52, 52, 0.7);
            padding: 12px 15px ;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            font-weight: 400;
            letter-spacing: 0.5px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &.active,&:hover {
                color: rgba(52, 52, 52, 1);
                font-weight: 500;
            }
            .anticon {
                font-size: 12px;
                margin-right: 7px;
            }
        }
    }
}
@media only screen and (max-width: 1100px) {
    body {
        .ant-layout-header {
            .ant-input-affix-wrapper {
                min-width: 225px;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    body {
        .ant-layout-header {
            .topmenu {
                display: none;
            }
            .hlogo {
                min-width: 190px;
                max-width: 190px;
            }
        }
        .ant-layout-sider{
            position: fixed;
        }
        
    }
    .mobilemenu {
        display: inline-flex;
        height: 44px;
    }
   
    
}
@media only screen and (max-width: 767px) {
    body {
        .ant-layout-footer {
            .socialicons {
                margin-bottom: 16px;
                overflow: hidden;
            }
            ul.footerlinks {
                margin-bottom: 16px;
                overflow: hidden;
            }
        }
        .ant-layout-header {
            .ant-input-affix-wrapper {
                display: none;
            }
        }
    }
}
