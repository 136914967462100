@import '../common/colors.scss';
div.login-box {
    .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
            display: inline !important;
            width: 47%;
        }
    }
    .ant-tabs-ink-bar {
        width: 50% !important;
    }
}

.login-modal-box .ant-modal-header {
    display: none;
}

body {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        height: 46px;
        padding: 12px 15px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        border-radius: 36px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    
        line-height: 20px;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #e0e0e0;
        border-right-width: 1px !important;
    }
    .ant-input {
        border: 1px solid $midgray;
        min-height: 46px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        padding: 12px 15px;
        border-radius: 36px;
        &:-internal-autofill-selected {
            background-color: transparent !important;
        }
        &:-webkit-autofill {
            background-color: transparent !important;
        }
    }
    .ant-form-item-has-error .ant-form-item-explain,
    .ant-form-item-has-error .ant-form-item-split {
        color: #ff4d4f;
        text-align: right;
    }
    .ant-input:focus,
    .ant-input-focused {
        border-color: transparent;
        border: 1px solid $midgray;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .ant-input:hover {
        border-color: transparent;
        border: 1px solid $midgray;
    }
    .ant-form-item textarea.ant-input {
        resize: none;
    }
    .ant-input-affix-wrapper {
        border: 1px solid $midgray;
        min-height: 46px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        padding: 12px 15px;
        border-radius: 36px;
        &:hover {
            border-color: $midgray;
            box-shadow: none;
        }
        &:focus {
            box-shadow: none;
        }
        &.ant-input-affix-wrapper-focused {
            box-shadow: none;
        }
        .ant-input {
            min-height: 20px;
            border: 0;
        }
    }
    .ant-form-item {
        margin-bottom: 24px;
    }
    .ant-divider-horizontal {
        margin: 16px 0px 32px 0px;
        border-top: 1px solid #e7e7e7;
    }
    .ant-btn {
        background: $darkblue;
        min-height: 46px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $white;
        border: 0;
        border-radius: 36px;
        &:hover,
        &:focus {
            color: $white;
            background: $darkblue;
        }
        &.ant-btn-primary {
            background: $darkblue;
        }
        &.ant-btn-secondary {
            background: $offwhite;
            color: $darkgray;
            &:hover,
            &:focus {
                color: $darkgray;
                background: $offwhite;
            }
        }
        &.ant-btn-sm {
            font-size: 16px;
            min-height: 35px;
            display: inline-flex;
            align-items: center;
            padding: 0px 12px;
        }
        .anticon {
            font-size: 18px;
            display: inline-flex;
        }
        &.csv-btn{
            border-radius: 0px 36px 36px 0px;
            font-weight: 400;
            font-size: 16px;
        }
    }
    .ant-input-prefix {
        margin-right: 10px;
    }
    .inputsrch{
        > .ant-input-group > .ant-input-group-addon:last-child{
            border: 1px solid #e0e0e0;
            border-left: 0;
            padding-right: 5px;
        }
        &.ant-input-search .ant-input:hover, &.ant-input-search .ant-input:focus {
            border-color: #e0e0e0;
        }
        .ant-input-group-addon{
            background-color: transparent;
            border: 1px solid #e0e0e0;
            border-radius: 0px 60px 60px 0px;
            padding-right: 5px;
            border-left: 0;  
        }
        .ant-input{
            border-right: 0;
        }
        .ant-btn{
            background: none;
            background-color: transparent;
            min-height: inherit;
        }
    }
    .bottomButtons {
        .ant-btn {
            min-width: 150px;
        }
    }
    .ant-checkbox-inner {
        border: 1px solid $midgray;
        border-radius: 0;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $darkblue;
        border-color: $midgray;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $midgray;
    }
    .ant-checkbox-checked::after {
        border: 1px solid $midgray;
    }
}
.container {
    padding: 0px 15px;
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
}
.container-fluid {
    padding: 0px 16px;
    width: 100%;
    margin: 0 auto;
}
.authPage {
    min-height: 100vh;
    background-image: url(../../images/main-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-top: 132px;
    padding-bottom: 32px;
    .reg_wraper {
        width: 100%;
        max-width: 1000px;
        background: $white;
        padding: 0px 32px 32px 32px;
        margin: 0 auto;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        &.acverfiy{
            padding: 0 32px 32px 32px;
            min-width: 500px;
            max-width: 500px;
        }
        .checkwrap{
            margin-bottom: 5px;
            margin-top: 0px;
        }
        .pagename {
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.01em;
            color: $darkgray;
            position: relative;
            max-width: 530px;
            margin: 0 auto;
            &::after{
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                background: #dfdfdf;
                left: 0;
                top: 50%;
            }
            span{
                background: #fff;
                position: relative;
                z-index: 9;
                display: inline-block;
                padding: 40px 10px;
            }
        }
        p {
            margin: 0;
            padding: 0;
            margin-bottom: 24px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #5c5c5c;
        }
    }
    .login_wraper {
        display: inline-block;
        background: $white;       
        margin: 0 auto;
        max-width: 1000px;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        &.forgot_pass{
            max-width: 488px;
            padding: 0px 64px 32px 64px;
            .bottomButtons .ant-btn {
                min-width: 100px;
            }
            .pagename{
                color: $darkgray;
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 20px;
                position: relative;
                &::after{
                    content: "";
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    background: #dfdfdf;
                    left: 0;
                    top: 50%;
                }
                span{
                    background: #fff;
                    position: relative;
                    z-index: 9;
                    display: inline-block;
                    padding: 40px 10px;
                }
            }
        }
        p {
            margin: 0;
            padding: 0;
            margin-bottom: 24px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #5c5c5c;
        }
        .login_inr_wrp{
            display: flex;
            flex-wrap: nowrap;
            
            .formdiv{
                padding: 0px 32px 32px 32px;
                width: 100%;
                
                .ant-form-item{
                    margin-bottom: 34px;
                    text-align: left;
                }
                .checkwrap{
                    margin-bottom: 20px;
                    margin-top: -12px;
                }
                .pagename{
                    color: $darkgray;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 20px;
                    position: relative;
                    &::after{
                        content: "";
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        background: #dfdfdf;
                        left: 0;
                        top: 50%;
                    }
                    span{
                        background: #fff;
                        position: relative;
                        z-index: 9;
                        display: inline-block;
                        padding: 40px 10px;
                    }
                }
            }
            .loginimg_div{
                background: url(../../images/loginimg.png) no-repeat center;
                background-size: cover;
                min-width: 420px;
                border-left: 1px solid #E3E3E3;
            }
        }
        .ant-tabs-top > .ant-tabs-nav::before {
            border: 0;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
            justify-content: center;
            margin-left: -8px;
            margin-right: -8px;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
            width: 100%;
            max-width: 446px;
        }
        
        .ant-tabs-tab {
            margin: 0px 8px;
            background: $offwhite;
            border-bottom: 2px solid $offwhite;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 29px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.01em;
            color: $black;
            padding: 9px 15px;
            width: 50%;
            &.ant-tabs-tab-active {
                border-bottom: 2px solid $black;
            }
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: $black;
        }
        .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active{
            color: $black;
        }
        .ant-tabs-ink-bar {
            display: none;
        }
        .ant-btn.loginbtn {
            width: 100%;
            margin:  0px;
        }
        .btnparent{
            display: flex;
    justify-content: center;
    padding-top: 24px;
        }
        .button-link {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            text-decoration-line: none;
            color: #000000;
            background: none;
            border: 0;
            outline: 0;
            border-right: 1px solid #9a9a9a;
            padding: 0px 8px;
            &:last-child{
                border: 0;
            }
        }
        .iconswrap {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            margin-top: 70px;
            margin-bottom: 42px;
            .ant-btn {
                width: 100%;
            }
            .icondiv {
                margin-bottom: 24px;
            }
        }
    }
}
@media only screen and (max-width: 1000px) {
    .authPage .login_wraper .login_inr_wrp .loginimg_div{
        min-width: 315px;
    }
}

@media only screen and (max-width: 991px) {
    .container-fluid {
        padding: 0px 16px;
    }
    
    .authPage .login_wraper .login_inr_wrp .formdiv {
        padding: 0px 32px 32px 32px;
    }
    .authPage .login_wraper.forgot_pass{
        padding: 0px 32px 16px 32px;
    }
    .authPage .login_wraper .button-link {
        font-size: 14px;
        padding: 0px 7px;
    }
}
@media only screen and (max-width: 767px) {
    .authPage .login_wraper .login_inr_wrp .loginimg_div{
        display: none;
    }
    .authPage {
        .reg_wraper {
            padding:0px 16px 16px 16px;
            &.acverfiy{
                padding: 0px 16px 16px 16px;
            }
        }
        .login_wraper {
            .iconswrap {
                margin-top: 32px;
                margin-bottom: 0px;
            }
        }
    }
    body {
        .ant-btn {
            min-height: 42px;
            font-size: 16px;
            padding: 4px 10px;
        }
    }
    .ant-row.bottomButtons {
        justify-content: flex-end;
        .ant-btn {
            min-width: 90px;
            margin-bottom: 10px;
        }
    }
    .authPage .login_wraper .login_inr_wrp .formdiv .pagename span {
        padding: 24px 10px;
    }
    .authPage .login_wraper.forgot_pass .pagename span {
        padding: 24px 10px;
    }
    .authPage .reg_wraper .pagename span{
        padding: 24px 10px;
    }
    .authPage .login_wraper .login_inr_wrp .formdiv .pagename {
        font-size: 20px;
    }
    .authPage .login_wraper.forgot_pass .pagename{
        font-size: 20px;
    }
    .authPage .reg_wraper .pagename{
        font-size: 20px;
    }
    .authPage .login_wraper .login_inr_wrp .formdiv {
        padding: 0px 16px 16px 16px;
    }
    .authPage .login_wraper.forgot_pass{
        padding: 0px 16px 16px 16px;
    }
    .authPage .login_wraper .button-link{
        border:0;
    }
    body .ant-layout.front .ant-layout-header .logo{
        margin-right: 0;
    }
}
@media only screen and (max-width: 550px) {
    .authPage {
        .reg_wraper {
            &.acverfiy{
                min-width: 100%;
            }
        }
    }
    .authPage .login_wraper .btnparent {
        display: block;
    }
    .authPage .login_wraper .button-link {
        line-height: 30px;       
        display: block;
    }
}
