div.message-box {
    height: 30em;
    position: relative;
}

div.message-box h1 {
    font-size: 30px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
