@import "../common/colors.scss";
.ant-layout-sider-zero-width + .ant-layout {
  .contentpart {
    width: 100vw;
  }
}
.contentpart {
  padding-top: 90px;
  min-height: 100%;
  background: $lightgray;
  height: 100%;
  width: calc(100vw - 256px);
  .right_content_col {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    .pagename {
      padding: 10px 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 20px;
      color: #343534;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 55px;
      .ant-breadcrumb {
        font-size: 16px;
      }
    }
    .content_wraper {
      padding: 32px;
      padding-top: 0;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 1px;
        height: 1px;
      }
      &::-webkit-scrollbar-thumb {
        background: hsla(0, 0%, 100%, 0.2);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px hsla(0, 0%, 100%, 0.05);
      }
      &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.15);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px rgba(37, 37, 37, 0.05);
      }
    }
  }
}

.dashboardPage {
  .card_wrap {
    margin-bottom: 32px;
  }
  .ant-spin-nested-loading {
    position: relative;
    z-index: 9;
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: $darkblue;
    }
  }
  .ant-spin-dot-item {
    background-color: $darkblue;
  }

  .chart-wrapper {
    padding: 16px;
    background: #fff;
    border: 1px solid #ebebeb;
    width: 100%;
    position: relative;
    &.chart_mainwrap {
      margin-bottom: 32px;
    }
    .headingrow {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $darkgray;
    }
    .mainchart_title {
      text-align: center;
      padding: 16px;
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: $darkgray;
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 16px;
        &::before {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          max-width: 330px;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          background: #f1f1f1;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          margin: 0px 10px;
          padding-left: 20px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #7e7e7e;
          position: relative;
          &::before {
            content: "";
            width: 10px;
            height: 10px;
            left: 0;
            top: 4px;
            background: #2d99ff;
            display: inline-block;
            position: absolute;
            border-radius: 2px;
          }
          &.green {
            &::before {
              background: #81ddc6;
            }
          }
          &.purple {
            &::before {
              background: #826af9;
            }
          }
          &.pink {
            &::before {
              background: #ff4d8d;
            }
          }
          &.orange {
            &::before {
              background: #ff6c40;
            }
          }
          &.yellow {
            &::before {
              background: #ffe700;
            }
          }
          &.red {
            &::before {
              background: #f45e58;
            }
          }
        }
      }
    }
  }
  .subcardwrap {
    border-left: 1px solid #f1f1f1;
    padding: 16px 16px 0px 32px;
    height: 100%;
    .subcard {
      background: #42495a;
      padding: 16px;
      border-radius: 4px;
      text-align: center;
      color: #fff;
      margin-bottom: 32px;
      &.orange {
        background: #ec574c;
        margin: 0;
      }
      .count {
        width: 80px;
        height: 80px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        font-style: normal;
        font-weight: 500;
        font-size: 37px;
        line-height: 20px;
      }
      h3 {
        margin: 0;
        margin-top: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #fff;
      }
    }
  }
  .doctablewrap {
    background: #fff;
    border: 1px solid #ebebeb;
    padding: 16px;
    .headingrow {
      padding: 10px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $darkgray;
      h3 {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.01em;
        .count {
          width: 25px;
          height: 25px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #c4c4c4;
          color: $darkgray;
          margin-left: 5px;
          border-radius: 50%;
          font-size: 16px;
        }
      }
    }
    .ant-table-tbody > tr:last-child > td {
      border: 0;
    }
  }
  .empmonthwrap {
    background: #fff;
    border: 1px solid #ebebeb;
    padding: 16px;
    .headingrow {
      padding: 10px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $darkgray;
      h3 {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.01em;
        .count {
          width: 25px;
          height: 25px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #c4c4c4;
          color: $darkgray;
          margin-left: 5px;
          border-radius: 50%;
          font-size: 16px;
        }
      }
    }
    .ant-table-tbody > tr:last-child > td {
      border: 0;
    }
  }
  .ant-card {
    border: 1px solid #ebebeb;
    min-height: 100%;
    .ant-row {
      flex-flow: row nowrap;
      padding-right: 16px;
      align-items: center;
    }
    .ant-card-body {
      padding: 0;
      min-height: 100%;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 29px;
        line-height: 24px;
        color: $darkgray;
        margin: 0;
        margin-top: 4px;
      }
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #7e7e7e;
        margin: 0;
      }
      .cardicon {
        margin: 0px 15px 0px 0px;
      }
    }
  }

  .settingWrp {
    position: relative;
  }
  .ant-picker {
    border: 1px solid #e1e1e1;
    border-radius: 30px;
    min-height: 45px;
    &.ant-picker-range .ant-picker-active-bar {
      background: #b8b8b8;
    }
  }
  .productList {
    border: 1px solid #ebebeb;
    background: $white;
    &.mystatsgrid {
      margin-bottom: 32px;
      .statshead {
        display: inline-flex;
        .searchhproduct {
          margin-right: 8px;
        }
        .monthwrap {
          margin-right: 8px;
        }
      }
    }
    .noProduct {
      padding: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      .fav-button {
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 46px;
        background: #f5f5f5;
        padding: 5px 15px;
        border-radius: 25px;
        cursor: pointer;
        &.checked {
          background: #233f8f;
          color: #fff;
        }
        label {
          margin-right: 10px;
          font-size: 16px;
          opacity: 0.7;
          cursor: pointer;
        }
      }
      h3 {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
      .monthwrap {
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 36px;
          padding: 7px 15px;
        }
      }

      .searchhproduct {
        min-height: 36px;
        max-height: 36px;
        display: inline-block;
        vertical-align: top;
        .ant-input-affix-wrapper {
          border: 1px solid #e2e2e2;
          min-height: inherit;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
          box-shadow: none;
          min-width: 250px;
          vertical-align: top;
          border-radius: 0;
          padding: 5px 0;
          padding-left: 10px;
          .ant-input-suffix {
            font-size: 20px;
          }
        }
      }
      .settingdiv {
        display: flex;
        align-items: center;
        margin-left: 16px;
        a {
          color: #343434;
          .anticon {
            color: #343434;
            opacity: 0.5;
          }
        }
      }
      .searchhwrap {
        min-height: 46px;
        max-height: 46px;
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;
        border: 1px solid #e1e1e1;
        border-radius: 30px;
        .ant-input {
          min-height: 46px;
          background-color: transparent;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          padding: 5px 16px;
          min-width: 250px;
        }
        .ant-btn {
          background: none;
          min-height: 46px;
        }
        .ant-input-group-addon {
          background-color: transparent;
          min-width: 46px;
        }
        .ant-input-group {
          display: flex;
        }
        .anticon {
          font-size: 16px;
        }
        .ant-input-affix-wrapper {
          border: 0;
          min-height: 32px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
          box-shadow: none;
          min-width: 320px;
          vertical-align: top;
          border-radius: 5px;
          .ant-input-suffix {
            font-size: 16px;
          }
        }
        .ant-input-group > .ant-input:first-child,
        .ant-input-group-addon:first-child {
          border: 0;
        }
      }
      .ant-btn.ant-btn-sm {
        padding: 0px 16px;
      }
    }
    .loadmore-btn {
      margin: 16px 0px;
    }
  }

  .table_grid {
    .action {
      color: $darkgray;
      font-size: 20px;
    }
    .ant-table-thead > tr > th {
      background: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8e8e8e;
      text-transform: capitalize;
    }
    .ant-table-tbody > tr > td {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #343534;
    }
    .clickable-row {
      cursor: pointer;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px 32px;
  }
  .ant-pagination-item-active {
    border: 0;
  }
  .ant-pagination-item a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #8e8e8e;
  }
  .ant-pagination-item-active a {
    color: $white;
    background: $darkblue;
    border: 1px solid #ebebeb;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: 20px;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 16px;
    border: 0;
    margin: 0;
    padding: 0;
  }
  .paginate {
    padding: 16px 16px;
    display: flex;
    justify-content: space-between;
    .ant-select {
      margin-right: 10px;
      .ant-select-selector {
        height: 35px;
        padding: 6px 10px;
      }
    }
    .pages-count {
      display: flex;
      align-items: center;
    }
    .ant-btn {
      &.ant-btn-link {
        background: none;
        color: #8e8e8e;
        padding: 0 3px;
        min-height: 20px;
        &:hover {
          color: #343434;
        }
      }
    }
  }
}
.stngwrp {
  .draghead {
    display: flex;
    background: #f5f5f5;
    h3 {
      width: 50%;
      padding: 10px 16px;
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &.reorder {
    .ant-modal .ant-modal-body .scrolldiv > div {
      width: 100%;
      padding: 10px;
      margin: 0px 5px;
      padding-bottom: 0;
    }
    .ant-modal .ant-modal-body .scrolldiv {
        display: flex;
        justify-content: space-between;
        padding: 10px 5px;
        max-height: 550px;
        overflow: auto;
    }
    .ant-modal .ant-modal-body .scrolldiv > div {
      padding: 16px;
    }
    .ant-modal .ant-modal-body .scrolldiv > div > div {
      margin-bottom: 16px;
      padding: 10px 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
  .ant-modal {
    top: 45px;
    max-width: 1000px;
    .ant-btn {
      padding: 5px 15px;
      font-size: 14px;
      min-height: 28px;
    }
    .ant-modal-close {
      color: #fff;
    }
    .ant-modal-close-x {
      width: 40px;
      height: 40px;
      font-size: 16px;
      line-height: 45px;
    }
    .ant-modal-body {
      padding: 0;
      .scrolldiv {
        display: flex;
        justify-content: space-between;
        padding: 10px 5px;
        > div {
          width: calc(50% - 10px);
          padding: 10px;
          margin: 0px 5px;
          padding-bottom: 0;
          > div {
            margin-bottom: 10px;
            padding: 5px 10px;
            border-radius: 3px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
          }
        }
        &::-webkit-scrollbar {
          width: 5px;
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          background: #c4c4c4;
          border-radius: 3px;
          -webkit-box-shadow: inset 0 0 5px hsla(0, 0%, 100%, 0.05);
        }
        &::-webkit-scrollbar-track {
          background: #f5f5f5;
          border-radius: 3px;
          -webkit-box-shadow: inset 0 0 5px rgba(37, 37, 37, 0.05);
        }
      }
      ul {
        padding: 10px;
        li {
          border-bottom: 1px solid #e9e9e9;
          cursor: move;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &:last-child {
            border: 0;
          }
          .anticon {
            margin-right: 15px;
            margin-left: 10px;
            color: #727272;
          }
        }
      }
    }
    .ant-modal-header {
      padding: 10px 16px;
      color: #fff;
      background: #4f5565;
      border-bottom: 1px solid #f0f0f0;
      border-radius: 2px 2px 0 0;
      text-align: left;
      .ant-modal-title {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  .ant-modal-wrap {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 500px;
    left: auto;
    z-index: 99;
    overflow: visible;
  }
}
.quotewraper {
  background: $white;
  border: 1px solid #ebebeb;
  padding: 32px;
  .ant-form label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #343434;
    margin-bottom: 5px;
    display: block;
  }
}
.ReportScheduler {
  background: $white;
  border: 1px solid #ebebeb;
  padding: 0;
  .ant-form label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #343434;
    margin-bottom: 5px;
    display: block;
  }
  .ant-picker {
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 46px;
    padding: 12px 15px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    border-radius: 36px;
  }
  .ant-space {
    width: 100%;
    justify-content: space-between;
    &.scheduler-buttons {
      justify-content: flex-end;
    }
  }
  .ant-modal-title {
    font-size: 18px;
  }
  .ant-btn.ant-btn-sm.close {
    margin-top: 5px;
  }
  .ant-btn.ant-btn-link {
    color: #233f8f;
    padding: 0;
    background: none;
  }
}
.myaccount {
  border: 1px solid #ebebeb;
  background: #ffffff;
  padding: 0px 32px;
  margin-bottom: 32px;
  .myprofile {
    .prflrow {
      margin-bottom: 32px;
      flex-wrap: nowrap;
    }
    .heading {
      padding: 24px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 24px;
      h3 {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
    }
    .profileimg {
      max-width: 150px;
      .imgdiv {
        position: relative;
        width: 150px;
        height: 150px;
        border: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          max-width: 100%;
        }
        .ant-btn {
          min-height: 30px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .ant-upload.ant-upload-select-picture-card {
          width: 100%;
          height: 100%;
          margin-right: 0;
          margin-bottom: 0;
          background-color: transparent;
          border: 0;
        }
        .ant-upload-picture-card-wrapper {
          width: 30px;
          position: absolute;
          right: 0;
          bottom: 0;
          height: 30px;
        }
        .ant-upload.ant-upload-select-picture-card > .ant-upload {
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }
    }
    .prflcont {
      padding-left: 32px;
      max-width: 640px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #343434;
      label {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        opacity: 0.5;
        display: block;
      }
      .ant-form-item {
        font-size: 16px;
      }
      a {
        color: #343434;
      }
    }
  }
}
.changepass {
  border: 1px solid #ebebeb;
  background: #ffffff;
  padding: 0px 32px;
  .changepassword {
    padding-bottom: 32px;
    .heading {
      padding: 24px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $black;

      h3 {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
    }
  }
}
.employeeDetail_wraper {
  background: #ffffff;
  border: 1px solid #ebebeb;
  padding: 32px;
  margin-bottom: 32px;
  .noProduct {
    margin-bottom: 16px;
  }
  .heading {
    margin-bottom: 16px;
  }
  .ant-dropdown-button {
    .ant-btn {
      display: none;
    }
    .ant-btn.ant-dropdown-trigger {
      display: inline-block;
    }
  }
  .ant-btn.ant-dropdown-trigger {
    border-radius: 30px !important;
    overflow: hidden;
    width: 40px;
    height: 40px;
    margin-left: 0;
    padding-left: 0;
    min-height: inherit;
  }
  .ant-tabs {
    .ant-tabs-nav {
    }
    .ant-tabs-tab {
      display: block;
      margin: 0;
      background: #f6f6f6;
      padding: 10px 15px;
      margin-right: 2px;
      position: relative;

      .ant-tabs-tab-btn {
        color: #233f8f;
      }
      &.ant-tabs-tab-active {
        background: #233f8f;
        .ant-tabs-tab-btn {
          color: #fff;
        }
      }
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    display: none;
  }
  .ant-space-align-center.labelheader {
    .ant-btn.ant-btn-secondary {
      color: #343434;
      min-height: inherit;
      font-size: 14px;
      line-height: 27px;
      padding: 0px;
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;
      justify-content: center;
      .anticon {
        font-size: 14px;
        display: inline-flex;
      }
    }
  }
}
.historyDrawer {
  .ant-drawer-content-wrapper {
    max-width: 800px;
    width: calc(100% - 16px) !important;
  }
  label {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    opacity: 0.5;
    display: block;
    line-height: 16px;
  }
  .ant-form-item {
    font-size: 16px;
    font-weight: 500;
  }
  a {
    color: #343434;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-drawer-header {
    background: #4f5565;
    color: #fff;
  }
  .ant-drawer-title {
    font-size: 20px;
    color: #fff;
  }
  .ant-drawer-close {
    color: #fff;
  }
  .print-btn {
    margin-bottom: 16px;
  }
  .printhistory {
    .ant-form-item {
      margin-bottom: 0px;
      background-color: red;
    }
  }
}
.employeeDetail {
  padding-left: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #343434;
  label {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    opacity: 0.5;
    display: block;
    line-height: 16px;
  }
  .ant-form-item {
    font-size: 16px;
  }
  a {
    color: #343434;
  }
  .ant-form-item {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f1f1f1;
  }
  .ant-btn.ant-btn-sm {
    padding: 0px 16px;
  }
  .ant-timeline {
    .ant-timeline-item-tail {
      border-left: 4px solid #233f8f;
      margin-left: 2px;
    }
    .ant-btn.ant-btn-link {
      background: none;
      color: #233f8f;
      padding: 0;
      border-bottom: 2px solid #233f8f;
      border-radius: 0;
      min-height: inherit;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
      .anticon {
        font-size: 12px;
      }
    }
    .vtitle {
      .vinline {
        display: inline-flex;
      }
      .ant-form-item {
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 33px;
        letter-spacing: 0.01em;
        color: #000;
      }
      label {
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 33px;
        letter-spacing: 0.01em;
        color: #b8b8b8;
      }
    }
    .ant-form-item {
      font-size: 16px;
      border: 0;
      padding: 0;
      margin-bottom: 10px;
    }
    .ant-collapse {
      border: 0;
      background: none;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background: #f6f6f6;
      padding: 5px 16px;
      border-radius: 30px;
      font-size: 16px;
      border: 0;
      font-weight: 500;
    }
    .ant-collapse-content {
      border: 0;
    }
    .ant-collapse > .ant-collapse-item {
      border: 0;
    }
    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      left: auto;
      right: 12px;
      top: 11px;
    }
  }

  .ant-timeline-item-head {
    width: 16px;
    height: 16px;
    border: 3px solid transparent;
    border-radius: 100px;
  }
  .ant-timeline-item-head-blue {
    color: #233f8f;
    border-color: #233f8f;
  }
  .ant-timeline-item-tail {
    top: 10px;
    left: 7px;
    height: calc(100% - 10px);
  }

  .ant-collapse
    > .ant-collapse-item.ant-collapse-item-active
    > .ant-collapse-header {
    background: #233f8f;
    color: #fff;
  }
}
.uploadwrap {
  text-align: center;
  padding: 60px 32px;
  .linkhere {
    color: #233f8f;
    font-weight: 600;
  }
  .ant-form label {
    margin-bottom: 16px;
    margin-right: 10px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
  }
  .uploadedfilewrp {
    width: 100%;
    max-width: 1200px;
  }
  .uploadedfilewrp > div > div {
    box-shadow: none !important;
    span {
      background-color: #233f8f !important ;
      height: 5px !important;
    }
  }
  .uploadedfile {
    display: inline-flex;
    background: #f5f5f5;
    padding: 3px 5px 3px 15px;
    border-radius: 22px;
    font-size: 16px;
    margin: 0px 3px;
    margin-bottom: 10px;
    .ant-btn.ant-btn-secondary {
      min-height: inherit;
      font-size: 0;
      padding: 0;
      line-height: normal;
      margin: 0;
      background: none;
      height: auto;
      margin-left: 10px;
    }
    .ant-btn .anticon {
      font-size: 14px;
      display: inline-flex;
    }
  }
  .uploadcol {
    span {
      display: inline-block;
    }
    .ant-btn.ant-btn-sm {
      padding: 0px 16px;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #e0e0e0;
    min-height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding: 12px 20px;
    border-radius: 36px;
  }
  .ant-select-single.upload-dropdown:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 36px 0px 0px 36px;
    border-right: 0;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: auto;
    min-height: 46px;
  }
  .ant-select-arrow {
    top: 44%;
    right: 17px;
    font-size: 16px;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding: 12px 9px;
  }
}
.mapbtnrow {
  max-width: 1200px;
  margin: 0 auto;
  .ant-space {
    width: 100%;
    justify-content: space-between;
  }
  .ant-btn {
    min-width: 110px;
  }
}
.mapfields-wrap {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 32px;
  max-width: 1200px;
  margin: 0px auto;
  .ant-form-item {
    margin-bottom: 32px;
  }

  .filterwrp {
    .ant-select {
      min-width: 270px;
    }
    .ant-input {
      min-width: 270px;
    }
    .ant-space {
      width: 100%;
      &.right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .field-title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.01em;
    position: relative;
    text-align: left;
    margin-bottom: 32px;
    .tooltipwrp {
      background: #fff;
      position: absolute;
      right: 0;
      top: 0px;
      padding: 0px 0px 0px 10px;
      .anticon {
        font-size: 16px;
      }
    }
    span {
      display: inline-block;
      padding-right: 15px;
      background: #fff;
      position: relative;
      z-index: 9;
    }
    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      border-bottom: 1px solid #e3e3e3;
      content: "";
    }
  }
  .field-wrp {
    display: flex;
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 30px;
    }
    .ant-select {
      min-width: 190px;
      max-width: 100%;
    }
    .ant-form-item {
      width: 100%;
    }
    .ant-select-single.field-dropdown:not(.ant-select-customize-input)
      .ant-select-selector {
      border: 1px solid #e0e0e0;
      border-radius: 0;
      min-height: 56px;
      border-left: 1px solid #233f8f;
      font-size: 16px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    body
      .ant-select-single
      .ant-select-selector
      .ant-select-selection-placeholder {
      line-height: 32px;
    }
    label {
      font-size: 16px;
      height: 56px;
      padding: 0px 15px;
      background: #eaeff5;
      align-items: center;
      margin-right: 1px;
      display: flex;
      font-weight: 600;
      width: -webkit-fill-available;
      margin: 0;
      max-width: 50%;
    }
    .ant-select-arrow {
      top: 37%;
      right: 20px;
      font-size: 18px;
    }
  }
}
.managecontact {
}
.ReportPage {
  border: 1px solid #ebebeb;
  background: $white;
  padding: 32px;
  .grid_heading {
    background: #4f5565;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    padding: 12px 40px 12px 16px;
    &.export-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;
      .ant-space {
        width: auto;
      }
      .exp-btn {
        background: #fff;
        color: #233f8f;
      }
    }
  }

  .table-row-light td {
    background-color: #fff;
  }
  .table-row-dark td {
    background-color: #ecedef;
  }

  .ant-collapse {
    border: 0;
    background-color: transparent;
  }
  .ant-collapse > .ant-collapse-item {
    margin-bottom: 32px;
    border: 0;
    &.filtercollapse {
      margin-bottom: 24px;
    }
  }
  .ant-collapse-content {
    border: 1px solid #cbcfdc;
    background: #fafafa;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 32px;
  }
  .fields-heading {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    margin-bottom: -20px;
    a {
      color: #233f8f;
    }
    .anticon {
      transform: rotate(90deg);
    }
  }
  .filtercollapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 32px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #4f5565;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    padding: 12px 40px 12px 16px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: auto;
    right: 16px;
    transform: rotate(90deg);
  }
  .ant-collapse
    > .ant-collapse-item.ant-collapse-item-active
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: auto;
    right: 16px;
    transform: rotate(180deg);
  }
  .ant-form label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #343434;
    margin-bottom: 5px;
    display: block;
  }
  .ant-space {
    justify-content: space-between;
    width: 100%;
    .ant-space-item:first-child {
      flex: auto;
    }
  }
  .ant-transfer-list-header {
    display: none;
  }
  .ant-transfer-list-body {
    padding: 16px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
  .ant-transfer-list {
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 10px 0px 0px 10px;
    border-right: 0;
    width: 50%;
    &:last-child {
      background: #fafbff;
      border: 1px solid #e1e1e1;
      border-radius: 0px 10px 10px 0px;
    }
  }
  .ant-transfer-operation {
    background: #fff;
    padding: 5px;
    border: 1px solid #ededed;
    border-radius: 30px;

    .ant-btn.ant-btn-primary {
      background: none;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #233f8f;
      background-color: #233f8f;
      border-radius: 30px;
      padding: 0;
      min-height: inherit;
      margin: 5px 0px;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:active,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:hover {
      color: rgba(0, 0, 0, 0.25);
      background: none;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
      .anticon {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .ant-btn .anticon {
      font-size: 12px;
      color: #fff;
    }
  }
  .ant-tree-switcher + .ant-tree-checkbox {
    display: none;
  }
  .ant-tree-switcher.ant-tree-switcher-noop + .ant-tree-checkbox {
    display: inline-block;
  }
  .ant-tree-switcher + .ant-tree-checkbox + .ant-tree-node-content-wrapper {
    pointer-events: none;
  }
  .ant-tree-switcher.ant-tree-switcher-noop
    + .ant-tree-checkbox
    + .ant-tree-node-content-wrapper {
    pointer-events: inherit;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #233f8f;
    border-color: #233f8f;
  }
  .ant-tree-checkbox-checked::after {
    border: 1px solid #233f8f;
  }
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: #233f8f;
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #f5f5f5;
  }
  .ant-tree-checkbox-disabled.ant-tree-checkbox-checked
    .ant-tree-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.5);
  }
  .ant-transfer-list-content-item-checked {
    background-color: #f5f5f5;
  }
  .ant-transfer-list-content-item {
    padding: 3px 12px;
  }
  .checkboxrow {
    .lbl {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      margin-right: 10px;
    }
    .ant-checkbox + span {
      opacity: 0.8;
      margin-right: 10px;
    }
  }
  .filterrow {
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 16px;
    margin-bottom: 16px;
    .ant-col {
      padding-bottom: 5px;
    }
    .lbl {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      text-align: left;
      display: block;
    }
    .col_1 {
      border-right: 1px solid #d2d2d2;
      .ant-row {
        align-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
        justify-content: space-between;
        text-align: right;
      }
    }
    .col_2 {
      .ant-row {
        align-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .lbl {
        min-width: 70px;
        display: inline-block;
        text-align: left;
      }
      .plusicon {
        font-size: 22px;
        color: #5d5d5d;
        display: inline-block;
      }
    }
    .ant-select {
      width: 100%;
    }
    .ant-input {
      height: 36px;
      padding: 8px 15px;
      width: 100%;
      min-height: 36px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 36px;
      padding: 8px 15px;
    }
  }
  .btnrow {
    text-align: center;
    margin-bottom: 24px;
    .ant-space {
      width: auto;
    }
    .ant-btn {
      min-width: 140px;
    }
  }
}
.noevent {
  pointer-events: none;
}
@media only screen and (max-width: 1250px) {
  .mapfields-wrap .filterwrp .ant-select {
    min-width: inherit;
  }
  .mapfields-wrap .filterwrp .ant-input {
    min-width: inherit;
  }
}
@media only screen and (max-width: 1180px) {
  .dashboardPage
    .productList
    .noProduct
    .searchhproduct
    .ant-input-affix-wrapper {
    min-width: 200px;
    max-width: 96px;
  }
  .dashboardPage .productList .noProduct {
    padding: 16px 16px;
  }
}
@media only screen and (max-width: 991px) {
  .contentpart {
    .right_content_col {
      .pagename {
        padding: 16px;
      }
      .content_wraper {
        padding: 16px;
        padding-top: 0;
      }
    }
    padding-left: 0;
    width: 100vw;
  }
  .dashboardPage {
    .card_wrap {
      margin-bottom: 16px;
    }
    .dashboard-welcome-box {
      margin-bottom: 16px !important;
      padding: 16px;
      .left_content {
        h3 {
          font-size: 32px;
          line-height: 38px;
        }
      }
      .pluseDivider {
        margin-top: 66px;
      }
      .content_overlay {
        padding: 16px 16px 0px 16px;
      }
    }
    .productList {
      .noProduct {
        padding: 16px;
      }
    }
  }
  .myaccount {
    padding: 0px 16px;
    margin-bottom: 16px;
  }
  .changepass {
    padding: 0px 16px;
  }
  .changepass .changepassword {
    padding-bottom: 16px;
  }
  .quotewraper {
    padding: 16px;
  }
  .ReportPage {
    padding: 16px;
  }
  .ReportPage .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
  }
  .ReportPage .ant-transfer-list-body {
    padding: 16px 16px 16px 5px;
  }
  .ant-tree,
  .ant-transfer-list-body {
    font-size: 13px;
  }
  .ReportPage .filterrow .lbl {
    font-size: 13px;
  }
  .ReportPage .checkboxrow .lbl {
    font-size: 13px;
  }

  .mapfields-wrap .filterwrp .ant-space .ant-space-item {
    width: 100%;
    text-align: left;
  }
}
@media only screen and (max-width: 767px) {
  .contentpart .right_content_col .pagename {
    display: block;
    padding: 16px;
    min-height: auto;
  }
  .contentpart .right_content_col .pagename .ant-breadcrumb {
    font-size: 14px;
  }
  .dashboardPage {
    .ant-card {
      min-height: inherit;
      margin-bottom: 16px;
    }
  }
  .dashboardPage .productList .noProduct .searchhproduct {
    display: none;
  }
  .dashboardPage .subcardwrap {
    border-left: 0;
    padding: 16px 0px;
    height: 100%;
    display: flex;
  }
  .dashboardPage .subcardwrap .subcard {
    margin-bottom: 0;
    width: 50%;
    margin: 0px 8px;
  }
  .dashboardPage .subcardwrap .subcard.orange {
    margin: 0px 8px;
  }
  .dashboardPage .card_wrap {
    margin-bottom: 0;
  }
  .dashboardPage .doctablewrap {
    margin-bottom: 16px;
  }
  .ReportPage .filterrow .col_1 {
    border: 0;
  }
}
@media only screen and (max-width: 600px) {
  .myaccount .myprofile .profileimg .imgdiv {
    width: 100px;
    height: 100px;
  }
  .myaccount .myprofile .prflcont {
    padding-left: 16px;
  }
  .mapfields-wrap .field-wrp {
    flex-wrap: wrap;
  }
  .mapfields-wrap .field-wrp label {
    height: auto;
    max-width: 100%;
  }
  .mapfields-wrap {
    box-shadow: none;
    padding: 16px 0px 0px 0px;
  }
}
@media only screen and (max-width: 400px) {
  .dashboardPage .productList.mystatsgrid .statshead {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .dashboardPage .productList.mystatsgrid .statshead .monthwrap {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .myaccount .myprofile .prflrow {
    margin-bottom: 16px;
    flex-wrap: wrap;
  }
  .myaccount .myprofile .profileimg .imgdiv {
    width: 150px;
    height: 150px;
    margin-bottom: 16px;
  }
  .myaccount .myprofile .prflcont {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .dashboardPage .card_wrap {
    margin-bottom: 16px;
  }
  .dashboardPage .chart-wrapper {
    margin-bottom: 16px;
  }
  .dashboardPage .empmonthwrap {
    margin-top: 0;
  }
  .contentpart .right_content_col .pagename {
    padding: 10px 16px;
  }
  .dashboardPage .chart-wrapper.chart_mainwrap {
    margin-bottom: 16px;
  }
  .dashboardPage .productList.mystatsgrid {
    margin-bottom: 16px;
  }
}
